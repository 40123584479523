import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import { authActivate, authNewKeyActivate, checkAuth, logout } from "../../services/auth";
import { setNotification } from "../../store/reducers/notificationSlice";
import { setUser } from "../../store/reducers/authSlice";
import { Timer } from "../../helpers/all";

const Activate = () => {
  const [key, setKey] = useState();
  const auth = useSelector((state) => state?.auth?.isAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => state?.auth?.user?.email);
  const status = useSelector((state) => state?.auth?.user?.status);
  const [endTimer, setEndTimer] = useState(false);
  useEffect(() => {
    if (!auth) {
      return navigate("/");
    }
  }, [auth]);
  useEffect(() => {
    if (status == 1) {
      return navigate("/account/settings");
    }
  }, [status]);
  const onKey = useCallback((key) => {
    setLoading(true);
    authActivate(key)
      .then(() => {
        checkAuth()
          .then((data) => {
            data && dispatch(setUser(data));
          })
          .catch(() => dispatch(logout()))
          .finally(() => setLoading(false));
        NotificationManager.success("Ваш аккаунт подтвержден")
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      })
      .finally(() => setLoading(false));
  }, [authActivate, dispatch, NotificationManager, setLoading]);

  const getKey = useCallback(() => {
    setEndTimer(false)
    authNewKeyActivate()
      .then(() => {
        NotificationManager.success("Код подтверждения отправлен повторно");

        setLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
        setLoading(false);
      });
  }, []);


  return (
    <main>
      <Meta title="Подтверждение почты" />
      <Container>
        <section className="hv-100 sec-login d-flex flex-column align-items-center justify-content-center">
          <h1 className="h3 text-center">Введите код, отправленный на указанную электронную почту {email}</h1>
          <Row className='g-3 g-md-4 mb-5 mt-1 justify-content-center activate'>
            {/* <Col md={4}> */}
            <input className='code' type="number" placeholder='0000' value={key} onChange={(e) => { e.target.value.length < 5 && setKey(e.target.value) }} />
            {endTimer ? (
              <p className="text-center pointer " onClick={() => getKey()}>
                <b><u> Отправить повторно код подтверждения</u></b>
              </p>
            ) : (
              <p className="text-center">
                Повторить отправку кода через <Timer onEnd={() => setEndTimer(true)} /> сек
              </p>
            )}
            {/* </Col>
              <Col md={8}> */}
            <button type='button' className='btn-1 h-100 w-100' disabled={!key || key?.length < 4 || loading} onClick={() => onKey(key)}>Отправить</button>
            {/* </Col> */}
            <button type='button' className='btn-1 h-100 w-100' onClick={() => dispatch(logout())}>Выйти</button>
          </Row>
        </section>

      </Container>
    </main>
  );
};

export default Activate;
