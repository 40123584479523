import React from 'react';
import { Link } from 'react-router-dom';
import useIsMobile from '../hooks/isMobile';
import { getImageURL } from '../helpers/all';

const BlogCard = ({ uid, media, title, content }) => {
  const isMobileLG = useIsMobile('1109px');
  const image = getImageURL({ path: media, size: "", type: "blog" })
  return (
    <figure className="article-midi">
      <img src={image} alt={title} />
      <figcaption>
        <h6>{title}</h6>
        <div className="article-midi-text" dangerouslySetInnerHTML={{ __html: content }}>
        </div>
        <Link to={`/blog/${uid}`} className='article-midi-more'>Подробнее</Link>
      </figcaption>
    </figure>
  );
};

export default BlogCard;